
import { defineComponent, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";


import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import eventBus from "../../../event-buss";
// import trigger from "element-plus/lib/el-popper/src/renderers/trigger";

interface NewAddressData {
  title: string;
  type: string;
  dueDate: string;
  details: string;
  tags: Array<string>;
  notifications: Array<string>;
  configurable: boolean;
}

export default defineComponent({
  name: "new-target-modal",
  components: {},
  props: {
    targetId: String,
    loadUpdates: Boolean,
  },
  mounted() {
    let btn  = document.getElementById("createNewPost");
    if(btn){
      btn.addEventListener("click", e => {
        let o = {
          _id: "",
          title: "",
          type: "",
          dueDate: "",
          details: "",
          tags: [],
          notifications: ["app"],
          configurable: true,
        };
        Object.assign(this.targetData, o);
      });
    }

    eventBus.$on("update-form", (data) => {
      let o = JSON.parse(JSON.stringify(data[0]));
      o.dueDate = new Date(o.dueDate * 1000).toDateString();
      // console.log(o);
      Object.assign(this.targetData, o);
    });
   
      
  },
  beforeMount() {
    if (this.loadUpdates) {
      this.updateLogs();
    }
  },

  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);

    

    let targetData = reactive(
      ref<NewAddressData>({
        title: "",
        type: "",
        dueDate: "",
        details: "",
        tags: [],
        notifications: ["app"],
        configurable: true,
      })
    );

    
    const resetModal = () => {
      let modal = document.getElementById("postComponent");
      if (modal) {
        modal.addEventListener("hidden.bs.modal", function (event) {
          let o = {
            _id: "",
            title: "",
            type: "",
            dueDate: "",
            details: "",
            tags: [],
            notifications: ["app"],
            configurable: true,
          };
          Object.assign(targetData, o);
        });
      }
      let e = document.getElementById("kt_modal_new_target_cancel");
      if (e) {
        e.click();
      }
    };

    const rules = ref({
      details: [
        {
          required: true,
          message: "Please describe you post",
          trigger: "blur",
        },
      ],
      title: [
        {
          required: true,
          message: "Please enter the post title",
          trigger: "blur",
        },
      ],
      type: [
        {
          required: true,
          message: "Please select the type of your post",
          trigger: "change",
        },
      ],
      dueDate: [
        {
          required: true,
          message: "Date field is required",
          trigger: "change",
        },
      ],
      tags: [
        {
          required: true,
          message: "Please select priority level",
          trigger: "change",
        },
      ],
    });
    const store = useStore();
    const url = store.getters.getURL;

    
    const updateLogs = () => {
      store.dispatch(Actions.GET_UPDATES)
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        // console.log(targetData.value);
        let data = { ...targetData.value };
        data.notifications = [...data.notifications];
        if (valid) {
          loading.value = true;
          store.dispatch(Actions.POST_UPDATES, data).then( () => {
              loading.value = false;
              updateLogs();
              resetModal();
          });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      resetModal,
      updateLogs,
      targetData,
      submit,
      loading,
      formRef,
      rules,
    };
  },
});
